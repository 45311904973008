import React from 'react';
import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import ServicesContent from '../components/ServicesContent';
import ServicesPricing from '../components/ServicesPricing';
import ServicesName from '../components/ServicesName';
import WeReOpen from '../components/WeReOpen';

const Services = () => {
    return (
        <>
            <Helmet>
                <title>Services - THE CUTTING BAR</title>
            </Helmet>
            <PageBanner imageUrl="assets/images/background/2.jpg" heading="Services" />
            <ServicesContent />
            <ServicesPricing />
            <ServicesName />
            <WeReOpen />
        </>
    );
}

export default Services;
