import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const ServicesContent = () => {
    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);
    return (
        <section aria-label="section" className="no-top no-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 jarallax" data-jarallax-element="-20">
                        <p className="lead big wow fadeInUp">
                            Step into our stylish and comfortable space, where the blend of vintage and contemporary decor sets the perfect backdrop for your grooming journey. We pay attention to every detail, from the moment you walk through our doors until you leave with a fresh, confident look.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default ServicesContent;
