import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';
import { NavLink } from 'react-router-dom';

const OurTeam = () => {

    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    return (
        <section aria-label="section" className="no-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="wow fadeIn">Our Team</h2>
                        <div className="de-separator"></div>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-20">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/team/1.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                            <div className="d-social">
                                <NavLink to="" target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                            </div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Steven Porreca</h4>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-60">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/team/2.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                            <div className="d-social">
                                <NavLink to="" target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                            </div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Huey Apicella</h4>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-40">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/team/3.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                            <div className="d-social">
                                <NavLink to="" target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                            </div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Harry Riecke</h4>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-10">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/team/4.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                            <div className="d-social">
                                <NavLink to="" target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                            </div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Merilyn Axe</h4>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default OurTeam;
