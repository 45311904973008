import React from 'react';
import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/ContactForm';
import FollowUs from '../components/FollowUs';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us - THE CUTTING BAR</title>
            </Helmet>
            <PageBanner imageUrl="assets/images/background/5.jpg" heading="Contact Us" />
            <ContactInfo />
            <ContactForm />
            <FollowUs />
        </>
    );
}

export default Contact;
