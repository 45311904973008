import React from 'react';
import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import TestimonialsBox from '../components/TestimonialsBox';

const Testimonials = () => {
    return (
        <>
            <Helmet>
                <title>Testimonials - THE CUTTING BAR</title>
            </Helmet>
            <PageBanner imageUrl="assets/images/background/2.jpg" heading="Testimonials" />
            <TestimonialsBox />
        </>
    );
}

export default Testimonials;
