import React, { useState } from 'react';

const BookingInfo = () => {

    const [activeDiv, setActiveDiv] = useState('8AM');

    const handleClick = (divId) => {
        setActiveDiv(divId);
    };

    const [activeBox, setActiveBox] = useState('choosestaffbox');

    const clickBox = (divId) => {
        setActiveBox(divId);
    };

    return (
        <section id="section-form" className="no-top">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-xl-11 col-xxl-10">
                        <form name="contactForm" id='contact_form' className="form-border" method="post">
                            <h3 className="s2">Choose Services</h3>
                            <div className="row">
                                <div className="col-xl-3 col-lg-6">
                                    <div className="sc-group">
                                        <h5>Haircuts</h5>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Haircuts" id="s_a1" value="Regular Haircut" />
                                            <label htmlFor="s_a1">Regular Haircut</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Haircuts" id="s_a2" value="Scissors Haircut" />
                                            <label htmlFor="s_a2">Scissors Haircut</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Haircuts" id="s_a3" value="Kids Haircut" />
                                            <label htmlFor="s_a3">Kids Haircut</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6">
                                    <div className="sc-group">
                                        <h5>Shave</h5>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Shave" id="s_b1" value="Head Shave" />
                                            <label htmlFor="s_b1">Head Shave</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Shave" id="s_b2" value="Royal Shave" />
                                            <label htmlFor="s_b2">Royal Shave</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Shave" id="s_b3" value="Royal Head Shave" />
                                            <label htmlFor="s_b3">Royal Head Shave</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Shave" id="s_b4" value="Beard Trim No Shave" />
                                            <label htmlFor="s_b4">Beard Trim No Shave</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Shave" id="s_b5" value="Beard Trim Shave" />
                                            <label htmlFor="s_b5">Beard Trim Shave</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services" id="s_b6" value="Beard Shave Up" />
                                            <label htmlFor="s_b6">Beard Shave Up</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6">
                                    <div className="sc-group">
                                        <h5>Facial</h5>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Facial" id="s_c1" value="Deep Pore Cleansing" />
                                            <label htmlFor="s_c1">Deep Pore Cleansing</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Facial" id="s_c2" value="Aromatherapy Facial" />
                                            <label htmlFor="s_c2">Aromatherapy Facial</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Facial" id="s_c3" value="Acne Problem Facial" />
                                            <label htmlFor="s_c3">Acne Problem Facial</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Facial" id="s_c4" value="European Facial" />
                                            <label htmlFor="s_c4">European Facial</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Facial" id="s_c5" value="Glycolic Peel Facial" />
                                            <label htmlFor="s_c5">Glycolic Peel Facial</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6">
                                    <div className="sc-group">
                                        <h5>Package</h5>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Package" id="s_d1" value="Haircut + Shave" />
                                            <label htmlFor="s_d1">Haircut + Shave</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Package" id="s_d2" value="Haircut + Beard Trim" />
                                            <label htmlFor="s_d2">Haircut + Beard Trim</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Package" id="s_d3" value="Haircut + Beard Trim Shave" />
                                            <label htmlFor="s_d3">Haircut + Beard Trim Shave</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="Services Package" id="s_d4" value="Haircut + Beard Shape Up" />
                                            <label htmlFor="s_d4">Haircut + Beard Shape Up</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer-single"></div>
                            <div className="row">
                                <div className="col-lg-6 mb-sm-30">
                                    <h3 className="s2">Choose Staff</h3>
                                    <div className="choose-staff">
                                        <div className={`choose-staff-box ${activeBox === 'choosestaffbox' ? 'active' : ''}`} onClick={() => clickBox('choosestaffbox')}>
                                            <img src="assets/images/team/1.jpg" alt="Steven" />
                                            <div className="staff-name">Steven</div>
                                        </div>
                                        <div className={`choose-staff-box ${activeBox === 'choosestaffbox2' ? 'active' : ''}`} onClick={() => clickBox('choosestaffbox2')}>
                                            <img src="assets/images/team/2.jpg" alt="Huey" />
                                            <div className="staff-name">Huey</div>
                                        </div>
                                        <div className={`choose-staff-box ${activeBox === 'choosestaffbox3' ? 'active' : ''}`} onClick={() => clickBox('choosestaffbox3')}>
                                            <img src="assets/images/team/3.jpg" alt="Harry" />
                                            <div className="staff-name">Harry</div>
                                        </div>
                                        <div className={`choose-staff-box ${activeBox === 'choosestaffbox4' ? 'active' : ''}`} onClick={() => clickBox('choosestaffbox4')}>
                                            <img src="assets/images/team/4.jpg" alt="Axe" />
                                            <div className="staff-name">Axe</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h3 className="s2">Select Date</h3>
                                    <input type="date" name="date" id="date" className="form-control" placeholder="dd/mm/yyyy" required />
                                    <div className="spacer-single"></div>
                                    <h3 className="s2">Select Time</h3>
                                    <div className="select-time-box">
                                        <ul>
                                            <li className={`${activeDiv === '8AM' ? 'active' : ''}`} onClick={() => handleClick('8AM')}>8:00 AM</li>
                                            <li className={`${activeDiv === '9AM' ? 'active' : ''}`} onClick={() => handleClick('9AM')}>9:00 AM</li>
                                            <li className={`${activeDiv === '10AM' ? 'active' : ''}`} onClick={() => handleClick('10AM')}>10:00 AM</li>
                                            <li className={`${activeDiv === '11AM' ? 'active' : ''}`} onClick={() => handleClick('11AM')}>11:00 AM</li>
                                            <li className={`${activeDiv === '12AM' ? 'active' : ''}`} onClick={() => handleClick('12AM')}>12:00 AM</li>
                                            <li className={`${activeDiv === '1PM' ? 'active' : ''}`} onClick={() => handleClick('1PM')}>1:00 PM</li>
                                            <li className={`${activeDiv === '2PM' ? 'active' : ''}`} onClick={() => handleClick('2PM')}>2:00 PM</li>
                                            <li className={`${activeDiv === '3PM' ? 'active' : ''}`} onClick={() => handleClick('3PM')}>3:00 PM</li>
                                            <li className={`${activeDiv === '4PM' ? 'active' : ''}`} onClick={() => handleClick('4PM')}>4:00 PM</li>
                                            <li className={`${activeDiv === '5PM' ? 'active' : ''}`} onClick={() => handleClick('5PM')}>5:00 PM</li>
                                            <li className={`${activeDiv === '6PM' ? 'active' : ''}`} onClick={() => handleClick('6PM')}>6:00 PM</li>
                                            <li className={`${activeDiv === '7PM' ? 'active' : ''}`} onClick={() => handleClick('7PM')}>7:00 PM</li>
                                            <li className={`${activeDiv === '8PM' ? 'active' : ''}`} onClick={() => handleClick('8PM')}>8:00 PM</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer-single"></div>
                            <div className="row">
                                <h3 className="s2">Your details</h3>
                                <div className="col-lg-6">
                                    <div id='name_error' className='error'>Please enter your name.</div>
                                    <div className="mb25">
                                        <input type='text' name='Name' id='name' className="form-control" placeholder="Your Name" required />
                                    </div>
                                    <div id='email_error' className='error'>Please enter your valid E-mail ID.</div>
                                    <div className="mb25">
                                        <input type='email' name='Email' id='email' className="form-control" placeholder="Your Email" required />
                                    </div>
                                    <div id='phone_error' className='error'>Please enter your phone number.</div>
                                    <div className="mb25">
                                        <input type='text' name='phone' id='phone' className="form-control" placeholder="Your Phone" required />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div id='message_error' className='error'>Please enter your message.</div>
                                    <div>
                                        <textarea name='message' id='message' className="form-control" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-12 text-center">
                                    <div className="g-recaptcha" data-sitekey="6LdW03QgAAAAAJko8aINFd1eJUdHlpvT4vNKakj6" align="center"></div>
                                    <p id='submit' className="mt20">
                                        <input type='submit' id='send_message' value='Submit Form' className="btn-main" />
                                    </p>
                                </div>
                            </div>
                        </form>
                        <div id="success_message" className='success'>
                            Your message has been sent successfully. Refresh this page if you want to send more messages.
                        </div>
                        <div id="error_message" className='error'>
                            Sorry there was an error sending your form.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default BookingInfo;
