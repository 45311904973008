import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TrendingItem from './TrendingItem';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';


const TrendingStyles = () => {

    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    const TrendingBox = [
        {
            trendingname: "Slick Back",
            trendingnumber: "#1",
            img: "assets/images/hairstyles/1.jpg"
        },
        {
            trendingname: "Classic Quiff",
            trendingnumber: "#2",
            img: "assets/images/hairstyles/2.jpg"
        },
        {
            trendingname: "Pompadour",
            trendingnumber: "#3",
            img: "assets/images/hairstyles/3.jpg"
        },
        {
            trendingname: "Fringe",
            trendingnumber: "#4",
            img: "assets/images/hairstyles/4.jpg"
        },
        {
            trendingname: "Curly Fade",
            trendingnumber: "#5",
            img: "assets/images/hairstyles/5.jpg"
        },
        {
            trendingname: "Undercut",
            trendingnumber: "#6",
            img: "assets/images/hairstyles/6.jpg"
        }  
    ];

    const options = {
        center:true,
        loop:true,
        margin:0,
        nav:true,
        navText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ],
        dots:false,
        responsive:{
            1000:{
                items:4
            },
            600:{
                items:2
            },
            0:{
                items:2
            }
        }
    };

    return (
        <section id="section-trending" className="trending pt80">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h2 className="wow fadeIn">Trending Styles</h2>
                        <div className="de-separator"></div>
                        <div className="spacer-single"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 jarallax" data-jarallax-element="-20">
                        <div className="d-carousel wow fadeInRight">
                            <OwlCarousel className="owl-carousel owl-theme no-hide owl-center" {...options}>
                                {
                                    TrendingBox.map(TrendingDetails => {
                                        return (
                                            <TrendingItem TrendingDetails={TrendingDetails} key={''+TrendingDetails.trendingnumber} />
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                        <div className="spacer-double"></div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default TrendingStyles;
