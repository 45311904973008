import React from 'react';
import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import PricingList from '../components/PricingList';

const Pricing = () => {
    return (
        <div className="pricing-main">
            <Helmet>
                <title>Pricing - THE CUTTING BAR</title>
            </Helmet>
            <PageBanner imageUrl="assets/images/background/2.jpg" />
            <PricingList />
        </div>
    );
}

export default Pricing;
