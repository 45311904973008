import { useEffect } from "react";
import $ from 'jquery';
import  'jquery.marquee';
import "jquery-ui-dist/jquery-ui";

const MarqueeBtm = () => {

    useEffect (() => {
        $(function(){
            $('.de-marquee-list').marquee({
                direction: 'left',
                duration: 50000,
                gap: 0,
                delayBeforeStart: 0,
                duplicated: true,
                startVisible: true
            });
        });
    },[])
    
    return (
        <section className="marquee-view no-top">
            <div className="wow fadeInRight d-flex">
                <div className="de-marquee-list wow">
                    <div className="d-item">
                        <span className="d-item-txt">Haircut</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Shave</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Faded</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Hair Dye</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Beard Trim</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Hair Color</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Facial</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Masage</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                        <span className="d-item-txt">Hair Wash</span>
                        <span className="d-item-display">
                            <i className="d-item-block"></i>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default MarqueeBtm;
