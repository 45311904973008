import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';
import { NavLink } from 'react-router-dom';

const WellCrafting = () => {
    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);
    return (
        <section className="no-top jarallax">
            <div className="de-gradient-edge-top"></div>
            <img src="assets/images/background/1.jpg" className="jarallax-img" alt="" />
            <div className="container relative z1000">
                <div className="row align-items-center">
                    <div className="col-lg-6 jarallax" data-jarallax-element="-30">
                        <img src="assets/images/misc/man-2.png" className="img-fluid wow fadeInRight" alt="" />
                    </div>
                    <div className="col-lg-6 jarallax" data-jarallax-element="-60">
                        <h2 className="wow fadeInRight" data-wow-delay=".3s">We’ll  Crafting <span className="id-color">Confidence</span> Through Sharp Style</h2>
                        <p className="wow fadeInRight" data-wow-delay=".4s">We take pride in providing top-notch grooming services that blend classNameic techniques with modern trends. Step into our warm and inviting space, where you'll find a team of skilled barbers dedicated to enhancing your style and confidence.</p>
                        <NavLink to="/book-now" className="btn-main wow fadeInRight" data-wow-delay=".5s">Book Now</NavLink>
                    </div>
                </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
        </section>
    )
};

export default WellCrafting;
