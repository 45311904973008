import React from 'react';

const ContactInfo = () => {
    return (
        <section id="section-content" className="no-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4 text-center med-m30">
                        <i className="fa-solid fa-location-dot de-icon gradient de-hover-scale text-dark mb20"></i>
                        <p className="lead no-bottom">Our Address</p>
                        <h4 className="s2">5-Indraprasth Nagar, Above Kasturi Super Market, Nr. Satnam Hospital, Amin Marg Rajkot - 360001, Gujarat</h4>
                    </div>
                    <div className="col-lg-4 col-md-4 text-center med-m30">
                        <i className="fa-solid fa-phone de-icon gradient de-hover-scale text-dark mb20"></i>
                        <p className="lead no-bottom">Phone Number</p>
                        <h4 className="s2">+91 94086 20201</h4>
                    </div>
                    <div className="col-lg-4 col-md-4 text-center med-m30">
                        <i className="fa-regular fa-envelope de-icon gradient de-hover-scale text-dark mb20"></i>
                        <p className="lead no-bottom">Email Address</p>
                        <h4 className="s2">thecuttingbar2021@gmail.com</h4>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default ContactInfo;
