import React from 'react';
import { NavLink } from 'react-router-dom';

const FollowUs = () => {
    return (
        <section aria-label="section" className="no-top sm-hide">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h4>Follow Us</h4>
                        <div className="spacer-20"></div>
                        <div className="social-icons big">
                            <NavLink to="" target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                            <NavLink to="" target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                            <NavLink to="" target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default FollowUs;
