import React, { useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/all.min.css";
import "./assets/css/animate.min.css";
import "./index.css";
import "./assets/css/coloring.css";
import Header from './components/Header';
import Home from "./routes/Home";
import About from "./routes/About";
import Services from "./routes/Services";
import Gallery from "./routes/Gallery";
import Testimonials from "./routes/Testimonials";
import Pricing from "./routes/Pricing";
import Contact from "./routes/Contact";
import BookNow from "./routes/BookNow";
import Footer from './components/Footer';
import WOW from 'wowjs';
import { Route, Routes } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';

function App() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (
        <>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/book-now" element={<BookNow />} />
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;