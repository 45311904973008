import { useEffect } from "react";
import $ from 'jquery';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';

const GalleryBox = () => {

    useEffect(() => {
        $('.image-link').magnificPopup({
            type: 'image'
        });
    }, []);

    return (
        <section className="gallery no-top no-bottom">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-lg-4 mb-24">
                        <div className="de-image-hover">
                            <a href="assets/images/gallery/gallery-item-1.jpg" className="image-link">
                                <span className="dih-title-wrap">
                                    <span className="dih-title">The Cutting Bar Barbershop</span>
                                </span>
                                <span className="dih-overlay"></span>
                                <img src="assets/images/gallery/gallery-item-1.jpg" className="lazy img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-24">
                        <div className="de-image-hover">
                            <a href="assets/images/gallery/gallery-item-2.jpg" className="image-link">
                                <span className="dih-title-wrap">
                                    <span className="dih-title">The Cutting Bar Barbershop</span>
                                </span>
                                <span className="dih-overlay"></span>
                                <img src="assets/images/gallery/gallery-item-2.jpg" className="lazy img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-24">
                        <div className="de-image-hover">
                            <a href="assets/images/gallery/gallery-item-3.jpg" className="image-link">
                                <span className="dih-title-wrap">
                                    <span className="dih-title">The Cutting Bar Barbershop</span>
                                </span>
                                <span className="dih-overlay"></span>
                                <img src="assets/images/gallery/gallery-item-3.jpg" className="lazy img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-24">
                        <div className="de-image-hover">
                            <a href="assets/images/gallery/gallery-item-4.jpg" className="image-link">
                                <span className="dih-title-wrap">
                                    <span className="dih-title">The Cutting Bar Barbershop</span>
                                </span>
                                <span className="dih-overlay"></span>
                                <img src="assets/images/gallery/gallery-item-4.jpg" className="lazy img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-24">
                        <div className="de-image-hover">
                            <a href="assets/images/gallery/gallery-item-5.jpg" className="image-link">
                                <span className="dih-title-wrap">
                                    <span className="dih-title">The Cutting Bar Barbershop</span>
                                </span>
                                <span className="dih-overlay"></span>
                                <img src="assets/images/gallery/gallery-item-5.jpg" className="lazy img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-24">
                        <div className="de-image-hover">
                            <a href="assets/images/gallery/gallery-item-6.jpg" className="image-link">
                                <span className="dih-title-wrap">
                                    <span className="dih-title">The Cutting Bar Barbershop</span>
                                </span>
                                <span className="dih-overlay"></span>
                                <img src="assets/images/gallery/gallery-item-6.jpg" className="lazy img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-24">
                        <div className="de-image-hover">
                            <a href="assets/images/gallery/gallery-item-7.jpg" className="image-link">
                                <span className="dih-title-wrap">
                                    <span className="dih-title">The Cutting Bar Barbershop</span>
                                </span>
                                <span className="dih-overlay"></span>
                                <img src="assets/images/gallery/gallery-item-7.jpg" className="lazy img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GalleryBox;
