import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setIsVisible(currentScrollPos > 400); // Show button when scrolled down 100px
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <NavLink className={`scroll-to-top-btn ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}></NavLink>
            <footer>
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-lg-4 text-lg-start text-center">
                            <div className="social-icons">
                                <NavLink to="" target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                <NavLink to="" target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                            </div>
                        </div>
                        <div className="col-lg-4 text-lg-center text-center">
                            <img src="assets/images/logo.png" className="" alt="" />
                        </div>
                        <div className="col-lg-4 text-lg-end text-center">
                            2024 © All Rights Reserved. The Cutting Bar
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
};

export default Footer;
