import React from 'react';
import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import BookingInfo from '../components/BookingInfo';

const BookNow = () => {
    return (
        <>
            <Helmet>
                <title>Book Now - THE CUTTING BAR</title>
            </Helmet>
            <PageBanner imageUrl="assets/images/background/6.jpg" heading="Booking" />
            <BookingInfo />
        </>
    );
}

export default BookNow;
