import React from 'react';
import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import GalleryBox from '../components/GalleryBox';

const Gallery = () => {
    return (
        <>
            <Helmet>
                <title>Gallery - THE CUTTING BAR</title>
            </Helmet>
            <PageBanner imageUrl="assets/images/background/2.jpg" heading="Gallery" />
            <GalleryBox />
        </>
    );
}

export default Gallery;
