import React from 'react';
import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import AboutContent from '../components/AboutContent';
import VisionMission from '../components/VisionMission';
import OurTeam from '../components/OurTeam';
import MarqueeBtm from '../components/MarqueeBtm';

const About = () => {
    return (
        <>
            <Helmet>
                <title>About - THE CUTTING BAR</title>
            </Helmet>
            <PageBanner imageUrl="assets/images/background/3.jpg" heading="About Us" />
            <AboutContent />
            <VisionMission />
            <OurTeam />
            <MarqueeBtm />
        </>
    );
}

export default About;
