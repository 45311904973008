import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';

const Header = () => {

    const [isHidden, setIsHidden] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            setIsHidden(prevScrollPos < currentScrollPos && currentScrollPos > 116);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    useEffect(() => {
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 116) {
                $('.transparent').addClass("smaller");
            }
            else {
                $('.transparent').removeClass("smaller");
            }
        });
    }, []);

    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle(
            "responsive_nav"
        );
    };

    return (
        <header className={isHidden ? 'hidden' : 'transparent header-mobile'}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex">
                            <div className="de-flex-col">
                                <div className="de-flex-col">
                                    <div id="logo">
                                        <NavLink to="/">
                                            <img className="logo-main" src="assets/images/logo.png" alt="Logo" />
                                            <img className="logo-mobile" src="assets/images/logo-mobile.png" alt="Logo" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="de-flex-col header-col-mid">
                                <ul id="mainmenu" className="nav-desktop">
                                    <li>
                                        <NavLink to="/" className="menu-item">Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about" className="menu-item">About</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/services" className="menu-item">Services</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/gallery" className="menu-item">Gallery</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/testimonials" className="menu-item">Testimonials</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/pricing" className="menu-item">Pricing</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact" className="menu-item">Contact</NavLink>
                                    </li>
                                </ul>
                                <ul id="mainmenu" className="nav-mobile" ref={navRef}>
                                    <li>
                                        <NavLink to="/" className="menu-item" onClick={showNavbar}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about" className="menu-item" onClick={showNavbar}>About</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/services" className="menu-item" onClick={showNavbar}>Services</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/gallery" className="menu-item" onClick={showNavbar}>Gallery</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/testimonials" className="menu-item" onClick={showNavbar}>Testimonials</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/pricing" className="menu-item" onClick={showNavbar}>Pricing</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact" className="menu-item" onClick={showNavbar}>Contact</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="de-flex-col">
                                <div className="menu_side_area">
                                    <NavLink to="/book-now" className="btn-main">Book Now</NavLink>
                                    <button id="menu-btn" className="openbtn" onClick={showNavbar}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
