import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';
import { NavLink } from 'react-router-dom';

const OurServices = () => {
    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);
    return (
        <section aria-label="section" className="no-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="wow fadeIn">Our Services</h2>
                        <div className="de-separator"></div>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-20">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/services/1.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Haircuts</h4>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-60">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/services/2.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Beard</h4>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-40">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/services/3.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Shaving</h4>
                    </div>
                    <div className="col-lg-3 text-center jarallax" data-jarallax-element="-10">
                        <div className="de-box-a">
                            <div className="d-image">
                                <img src="assets/images/services/4.jpg" alt="" />
                            </div>
                            <div className="d-deco-1"></div>
                            <div className="d-deco-2"></div>
                        </div>
                        <div className="spacer-20"></div>
                        <h4>Razor Blade</h4>
                    </div>
                </div>
                <div className="spacer-single"></div>
                <div className="text-center">
                    <NavLink to="/services" className="btn-main">All Services &amp; Prices</NavLink>
                </div>
            </div>
        </section>
    )
};

export default OurServices;
