import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const VisionMission = () => {
    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);
    return (
        <section className="vision-mission jarallax no-top">
            <div className="de-gradient-edge-top"></div>
            <img src="assets/images/background/1.jpg" className="jarallax-img" alt="" />
            <div className="container relative z1000">
                <div className="row gx-5">
                    <div className="col-lg-6 jarallax" data-jarallax-element="-50">
                        <div className="d-sch-table">
                            <h2 className="wow fadeIn text-center">Our Vision</h2>
                            <div className="de-separator"></div>
                            <p>At the heart of our vision is a commitment to preserving the time-honored traditions of barbering while seamlessly blending them with contemporary techniques and trends. We envision a space where heritage and innovation coexist harmoniously, creating an environment that appeals to the modern man seeking both classNameic sophistication and cutting-edge styles.</p>
                            <div className="d-deco"></div>
                        </div>
                    </div>
                    <div className="col-lg-6 jarallax" data-jarallax-element="-100">
                        <div className="d-sch-table">
                            <h2 className="wow fadeIn text-center">Our Mission</h2>
                            <div className="de-separator"></div>
                            <p>Our mission is to empower men to feel confident, stylish, and authentic in their appearance. Through our commitment to excellence, personalized service, inviting atmosphere, and a harmonious blend of tradition and innovation, we aspire to become the ultimate grooming destination for the modern gentleman.</p>
                            <div className="d-deco"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
        </section>
    )
};

export default VisionMission;
