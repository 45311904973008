import React from "react";
import HomeMain from '../components/HomeMain';
import HomeContent from '../components/HomeContent';
import TrendingStyles from '../components/TrendingStyles';
import WellCrafting from '../components/WellCrafting';
import OurServices from '../components/OurServices';
import WeReOpen from '../components/WeReOpen';
import MarqueeBtm from '../components/MarqueeBtm';

const Home = () => {
    return (
        <>
            <HomeMain />
            <HomeContent />
            <TrendingStyles />
            <WellCrafting />
            <OurServices />
            <WeReOpen />
            <MarqueeBtm />
        </>
    );
}

export default Home;
