import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';
import "jquery-ui-dist/jquery-ui";

const WeReOpen = () => {

    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    return (
        <section className="we-re-open jarallax no-top">
            <div className="de-gradient-edge-top"></div>
            <img src="assets/images/background/1.jpg" className="jarallax-img" alt="" />
            <div className="container relative z1000">
                <div className="row gx-5">
                    <div className="col-lg-6 text-center jarallax" data-jarallax-element="-50">
                        <div className="d-sch-table">
                            <h2 className="wow fadeIn">We're Open</h2>
                            <div className="de-separator"></div>
                            <div className="d-col">
                                <div className="d-title">Mon - Thu</div>
                                <div className="d-content id-color">7:30AM - 6:30PM</div>
                            </div>
                            <div className="d-col">
                                <div className="d-title">Friday</div>
                                <div className="d-content id-color">8:30AM - 8:30PM</div>
                            </div>
                            <div className="d-col">
                                <div className="d-title">Sat - Sun</div>
                                <div className="d-content id-color">9:30AM - 5:30PM</div>
                            </div>
                            <div className="d-deco"></div>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center jarallax" data-jarallax-element="-100">
                        <div className="d-sch-table">
                            <h2 className="wow fadeIn">Location</h2>
                            <div className="de-separator"></div>
                            <div className="d-col">
                                <div className="d-title">Address</div>
                                <div className="d-content id-color">5-Indraprasth Nagar, Above Kasturi Super Market, Nr. Satnam Hospital, Amin Marg Rajkot - 360001, Gujarat</div>
                            </div>
                            <div className="d-col">
                                <div className="d-title">Phone</div>
                                <div className="d-content id-color">+91 94086 20201</div>
                            </div>
                            <div className="d-col">
                                <div className="d-title">Email</div>
                                <div className="d-content id-color">thecuttingbar2021@gmail.com</div>
                            </div>
                            <div className="d-deco"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
        </section>
    )
};

export default WeReOpen;
