import React from 'react';
import { NavLink } from 'react-router-dom';

const TrendingItem = ({TrendingDetails}) => {
    const {trendingname, trendingnumber, img} = TrendingDetails;
    return (
            <div className="c-item">     
                <NavLink to="">
                    <span className="c-item_info">
                        <span className="c-item_title">{trendingname}</span>
                        <span className="c-item_wm">{trendingnumber}</span>
                    </span>
                    <div className="c-item_wrap">
                        <img src={img} className="lazy img-fluid" alt="Trending" />
                    </div>
                </NavLink>
            </div>
    )
};

export default TrendingItem;
