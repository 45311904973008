import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const PageBanner = ({ imageUrl, heading }) => {

    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    return (
        <section id="subheader" className="jarallax" data-jarallax> 
            <img src={imageUrl} className="jarallax-img" alt="" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <h1>{heading}</h1>
                        <div className="de-separator"></div>
                    </div>
                </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
        </section>

    );
}

export default PageBanner;