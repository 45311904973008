import React, { useEffect } from 'react';
import $ from 'jquery';
import  'jquery.marquee';
import "jquery-ui-dist/jquery-ui";
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const HomeMain = () => {

    useEffect (() => {
        $(function(){
            $('.wm-carousel').marquee({
                direction: 'left',
                duration: 20000,
                gap: 100,
                delayBeforeStart: 0,
                duplicated: true,
                startVisible: false
            });
        });
    },[])

    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    return (
        <section id="de-carousel" className="home-slider no-top no-bottom carousel slide text-light carousel-fade" data-mdb-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active jarallax">
                    <img src="assets/images/background/7.jpg" className="jarallax-img" alt="" />
                    <div className="d-content relative z1000">
                        <div className="wm wm-carousel mt-30" data-jarallax-element="-50">
                            <div className="wow fadeInRight">thecuttingbar <span className="t2">shop</span></div>
                        </div>  
                        <div className="top-center">
                            <div className="wow fadeInRight">
                                <h1 className="id-color">Make Your Own Style</h1>
                            </div>
                        </div>
                        <div className="v-center">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 offset-lg-3 d-flex">
                                        <img src="assets/images/misc/man.png" className="wow fadeInLeft" data-wow-delay=".3s" data-wow-duration="1.5s" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="de-gradient-edge-bottom"></div>
                </div>
            </div>
            <a className="carousel-control-prev" href="#de-carousel" role="button" data-mdb-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#de-carousel" role="button" data-mdb-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </section>
    )
};

export default HomeMain;
